import React from "react"

const Logos = () => {
    return (
        <div className="relative bg-blue-dark">
            <div className="w-full mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="h-full flex justify-center items-center align-middle">
                    <img className="max-w-2xl mx-auto" src="../assets/logos.png" alt="certification" />
                </div>
            </div>
        </div>
    )
}
export default Logos
